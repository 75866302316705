import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
  const { site, logo } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            url,
            title,
            titleTemplate,
            description,
            author,
            twitterUsername
          }
        }
        logo: contentfulAsset(contentful_id: { eq: "43y28Kbc4yjS1OH8an15xv" }) {
          title
          gatsbyImageData(
            layout: FIXED
            resizingBehavior: SCALE
            placeholder: TRACED_SVG
            width: 250
            formats: WEBP
            quality: 100
          )
        }
      }
    `
  );
  return { ...site.siteMetadata, logo };
};
