import { useStaticQuery, graphql } from "gatsby"

export const usePracticeCategories = () => {
  const { allContentfulPracticeAreaCategories } = useStaticQuery(
    graphql`
      query PracticeCategoriesData {
        allContentfulPracticeAreaCategories(
          sort: { fields: [title], order: ASC }
          filter: { node_locale: { eq: "en-US" } }
        ) {
          practiceCategories: edges {
            node {
              node_locale
              slug
              title
              body {
                raw
              }
              shortBody {
                raw
              }
              icon {
                title
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 128
                  height: 128
                  resizingBehavior: SCALE
                  placeholder: TRACED_SVG
                )
              }
              title
              image {
                title
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 350
                  resizingBehavior: THUMB
                  placeholder: TRACED_SVG
                )
              }
              backgroundImage: image {
                title
                gatsbyImageData(
                  layout: FULL_WIDTH
                  resizingBehavior: SCALE
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `
  )

  return allContentfulPracticeAreaCategories
}
