import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const LayoutHeader = () => {
  const { logo } = useSiteMetadata();
  const [isActive, setisActive] = React.useState(false);
  return (
    <>
      <nav className="navbar is-transparent is-fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <GatsbyImage
                image={getImage(logo)}
                alt={"Martin, Cukjati & Tom Logo"}
                width={logo.gatsbyImageData.width}
                height={logo.gatsbyImageData.height}
              />
            </Link>

            <div
              onClick={() => {
                setisActive(!isActive);
              }}
              onKeyDown={() => {
                setisActive(!isActive);
              }}
              role="button"
              className={`navbar-burger ${isActive ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarMenu"
              tabIndex={0}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          <div
            id="navbarMenu"
            className={`navbar-menu ${isActive ? "is-active" : ""}`}
          >
            <div className="navbar-end">
              <Link to="/" className="navbar-item">
                Home
              </Link>
              <Link
                to="/about/"
                className="navbar-item"
                partiallyActive={true}
                activeClassName="is-active has-text-primary"
              >
                About
              </Link>
              <Link
                to="/lawyers/"
                className="navbar-item"
                partiallyActive={true}
                activeClassName="is-active has-text-primary"
              >
                Lawyers
              </Link>
              <Link
                to="/practice-areas/"
                className="navbar-item"
                partiallyActive={true}
                activeClassName="is-active has-text-primary"
              >
                Practice Areas
              </Link>
              <Link
                to="/our-victories/"
                className="navbar-item"
                partiallyActive={true}
                activeClassName="is-active has-text-primary"
              >
                Our Victories
              </Link>
              <Link
                to="/contact/"
                className="navbar-item"
                partiallyActive={true}
                activeClassName="is-active has-text-primary"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LayoutHeader;
