import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons"
import {
  faLinkedin,
  faFacebookSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons"
import { usePracticeCategories } from "../hooks/use-practice-categories"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export default function LayoutFooter() {
  const { practiceCategories } = usePracticeCategories()
  const { logo } = useSiteMetadata()
  return (
    <footer className="footer">
      <div className="container">
        <div className="columns">
          <div className="column content mr-3">
            <div className="content">
              <Link to="/">
                <GatsbyImage
                  image={getImage(logo)}
                  alt={"Martin, Cukjati & Tom Logo"}
                  width={logo.gatsbyImageData.width}
                  height={logo.gatsbyImageData.height}
                />
              </Link>
            </div>
            <div class="media">
              <div class="media-left">
                <figure class="image">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </figure>
              </div>
              <div class="media-content">
                <div class="content">
                  <p>
                    1802 Blanco Road
                    <br />
                    San Antonio, Texas 78212
                  </p>
                </div>
              </div>
            </div>
            <div class="media">
              <div class="media-left">
                <figure class="image">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                </figure>
              </div>
              <div class="media-content">
                <div class="content">
                  <p>210-223-2627</p>
                </div>
              </div>
            </div>
            <div class="media">
              <div class="media-left">
                <figure class="image">
                  <FontAwesomeIcon icon={faEnvelope} />
                </figure>
              </div>
              <div class="media-content">
                <div class="content">
                  <a
                    href="mailto:attorney@mcfirm.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    attorney@mcfirm.com
                  </a>
                </div>
              </div>
            </div>
            <div className="is-size-2 content">
              <a
                href="https://www.facebook.com/san.antonio.lawyers.martin.cukjati.tom"
                target="_blank"
                rel="noreferrer"
                className="mr-2"
                aria-label="Facebook"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
              <a
                href="https://www.linkedin.com/company/martin-&-cukjati-l.l.p."
                target="_blank"
                rel="noreferrer"
                className="mr-2"
                aria-label="Linked In"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                href="https://twitter.com/MartinCukjati"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <FontAwesomeIcon icon={faTwitterSquare} />
              </a>
            </div>
          </div>
          <div className="column content footer-list">
            <h6>Information</h6>
            <ul>
              <li>
                <Link to="/about/">About</Link>
              </li>

              <li>
                <Link to="/lawyers/">Lawyers</Link>
              </li>
              <li>
                <Link to="/practice-areas/">Practice Areas</Link>
              </li>
              <li>
                <Link to="/our-victories/">Our Victories</Link>
              </li>
              <li>
                <Link to="/contact/">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="column content footer-list">
            <h3 className="is-size-6">Practice Areas</h3>
            <ul>
              {practiceCategories.map(({ node }) => (
                <li>
                  <Link to={`/practice-areas/${node.slug}/`}>{node.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="column content is-size-7">
            <p>
              This website is for informational purposes only and does not
              provide legal advice. Please do not act or refrain from acting
              based on anything you read on this site. Using this site or
              communicating with Martin, Cukjati &amp; Tom, LLP through this
              site does not form an attorney/client relationship. This site is
              legal advertising. Please review the{" "}
              <Link to="/disclaimer/">full disclaimer</Link> for more
              information.
            </p>
          </div>
        </div>
        <div className="level">
          <div className="level-left">
            <div className="level-item has-text-grey-dark is-size-7">
              Copyright © 2021 Martin Cukjati & Tom, LLP. All rights reserved.
            </div>
          </div>
          <div className="level-right is-size-7">
            <Link
              to="/privacy-policy/"
              className="level-item has-text-grey-dark mr-2"
            >
              Privacy Policy
            </Link>
            <Link
              to="/disclaimer/"
              className="level-item has-text-grey-dark ml-2"
            >
              Disclaimer
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
