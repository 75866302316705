import React from "react";
import "../scss/style.scss";
import LayoutFooter from "./layout-footer";
import LayoutHeader from "./layout-header";
import Helmet from "react-helmet";

class Template extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <>
        <Helmet>
          <body className="has-navbar-fixed-top" />
        </Helmet>
        <LayoutHeader />
        <div className="container">{children}</div>
        <LayoutFooter />
      </>
    );
  }
}

export default Template;
